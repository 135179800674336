import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";
import { CheckCircle } from "react-feather";

const AreasServedContainer = styled.div`
  h1 {
    font-size: calc(0.75vw + 1.5em);
  }
`;

function parseContent(string, altCity) {
  if (string) {
    return string.replace(/San Jose/g, altCity);
  }
}

export const AreasServed = (props) => {
  const { citiesList, className, serviceName = "", parentPageSlug, blogComponent } = props;

  const getServiceTitle = () => {
    if (serviceName.includes("Iron Gate")) return "Iron Gates";
    if (serviceName.includes("Wrought Iron Fencing"))
      return "Wrought Iron Fencing";
    if (serviceName.includes("Wrought Iron Gate")) return "Wrought Iron Gate";
    if (serviceName.includes("Railing")) return "Railing";
    if (serviceName.includes("Iron Fencing")) return "Iron Fencing";

    if (serviceName.includes("Vinyl Fencing")) return "Vinyl Fencing";
    if (serviceName.includes("Ornamental Fencing")) return "Ornamental Fencing";
    if (serviceName.includes("Wood Fencing")) return "Wood Fencing Installation";
    if (serviceName.includes("Chain Link Fencing")) return "Chain Link Fences";
    if (serviceName.includes("Metal Driveway Gates")) return "Driveway Gates";

  };

  const getServiceSlug = () => {
    if (serviceName.includes("Iron Gate")) return "iron-gate";
    if (serviceName.includes("Wrought Iron Fencing")) return "wrought-iron";
    if (serviceName.includes("Wrought Iron Gate")) return "wrought-iron";
    if (serviceName.includes("Railing")) return "iron-railings";
    if (serviceName.includes("Iron Fencing")) return "iron-fencing";

    if (serviceName.includes("Ornamental Fencing")) return "ornamental-fencing";
    if (serviceName.includes("Vinyl Fencing")) return "vinyl-fencing";
    if (serviceName.includes("Chain Link Fencing")) return "chain-link-fences";
    if (serviceName.includes("Driveway Gates")) return "driveway-gates";
    if (serviceName.includes("Wood Fencing")) return "wood-fencing-installation";

  };

  const serviceTitle = getServiceTitle();
  const serviceSlug = getServiceSlug();

  return (
    <AreasServedContainer className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <div className="container is-service-page">
        <div className="pt-8 pb-5">
        {!blogComponent ? (
            <div className="pt-10 pb-5 bg-white rounded-2xl">
              <span className="px-8 block text-2xl font-bold text-gray-900 font-display">Other Service Areas</span>
              <ul className="flex flex-wrap list-none p-8">
                {citiesList.map((item, i) => {
                  return (
                    <li key={i} className="w-1/2 md:w-1/3 flex space-y-4 md:space-y-6">
                      <div className="flex-shrink-0 mr-2">
                        <CheckCircle fontWeight={900} strokeWidth={3} size={20} className="text-brand-500 mt-2" />
                      </div>{" "}
                      <Link
                        style={{ marginTop: 0, textDecoration: "none" }}
                        className="text-gray-800 underline-none"
                        to={`/cities/${item.node.slug}/${serviceSlug}/`}
                      >
                        {item.node.location}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>{" "}
                  <Link
                    className="text-gray-800 hover:text-brand-600"
                    title={`${item.location + " " + (serviceTitle ? serviceTitle : "")}`}
                    to={`/${serviceSlug ? serviceSlug : ""}-${item.slug + "/"}`}
                  >
                    {item.location}
                  </Link>
                </li>
              ))}
              sfsf
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>{" "}
                  <Link
                    className="text-gray-800 hover:text-gray-800"
                    title={`${"Fence & Gates " + item.location + ", CA"}`}
                    to={`/cities/${item.slug}/`}
                  >
                    {item.location}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </AreasServedContainer>
  );
};
